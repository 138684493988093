<template>
<div>
  <b-col md="12" class="my-2">
    <custom-input
      v-if="variables"
      class="my-1"
      id="custom-input-r1"
      :possibleValues="variables"
      v-model="agent.register_1"
      advanced
    >
      <template #label>
        {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
        <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</span>
      </template>
    </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px" />
        <b-skeleton class="mb-25" width="100%" height="37px" />
        <b-skeleton class="mb-25" width="25%" height="14px" />
      </div>
  </b-col>

  <b-col>
    <div class="d-flex w-100">
      <b-form-group class="w-50 mr-2">
        <template #label>
            <small>{{ $t(`agent.list.${i18nKey}.type`) }}</small><helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>
        </template>
        <b-form-select
          size="md"
          v-model="input_type"
        >
          <b-form-select-option v-for="item in stringTypeOptions" :key="item.id" :value="item.value">
            {{$t( item.label ) }} - {{$t("common.terms.ex")}} : "{{ $t(item.example) }}"
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="w-25 ml-5 float-right">
        <template #label>
            <small>{{ $t(`agent.list.${i18nKey}.case`) }}</small><helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>
        </template>
        <b-form-select
        :disabled="input_type == 'numeric'" 
        
          size="md"
          v-model="input_case"
          :options="[
            {value: '.upper' , text: $t('enum.string_types.casing_options.upper')},
            {value: '.lower' , text:$t('enum.string_types.casing_options.lower')},
            {value: '' , text:$t('enum.string_types.casing_options.both')},
          ]"
        >

        </b-form-select>
      </b-form-group>
    </div>
  </b-col>

  <b-col md="12" class="my-2">
    <custom-input
      v-if="variables"
      class="my-1"
      id="custom-input-rd"
      :possibleValues="variables"
      v-model="agent.register_destiny"
      advanced
    >
      <template #label>
        {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
        <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
      </template>
    </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px" />
        <b-skeleton class="mb-25" width="100%" height="37px" />
        <b-skeleton class="mb-25" width="25%" height="14px" />
      </div>
  </b-col>


  <!-- ICON ======================== -->
  <b-col md="12" class="d-flex justify-content-center mt-0">
    <div>
      <p class="p-0 m-0 text-secondary text-center"><small>{{ $t(`agent.list.${i18nKey}.register_1.title`).toLowerCase() }}</small></p>
      <p class="text-favorite text-center m-0"><b>5</b></p>
  </div>
    <div>
      <p class=" m-2"><b>,</b></p>
    </div>            
    <div>
      <p class="p-0 m-0 text-secondary text-center"><small>{{ $t(`agent.list.${i18nKey}.type`).toLowerCase() }}</small></p>
      <p class="text-favorite text-center m-0"><b>{{ $t(`enum.string_types.alphanumeric.title`)+" "+ $t(`enum.string_types.casing_options.upper`)}}</b></p>
    </div>
    <b-icon icon="arrow-right-short" shift-v="-4" scale="2" class="mx-2 mt-2"></b-icon>
    <div>
      <p class="p-0 m-0 text-secondary text-center"><small>{{ $t(`common.terms.result`) }}</small></p>
      <p class="text-center"><b><span class="text-success">
        "RY4B2"
      </span></b>
      </p>
    </div>
  </b-col>
  <!-- ================================== -->
  

</div>

</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon, BFormSelect, BFormGroup, BFormSelectOption } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import StringTypes from "@/custom/class/Enum/StringTypes.js";

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BFormSelect,
    BFormGroup,
    BFormSelectOption,

  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 49,
      i18nKey: 'random_string',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {
            source: 7,
            value: ""
        },
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      input_case: "",
      input_type: "",

      variant_map: null,
      isSaving: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formatReg2(){
      if (this.input_type == "numeric"){
        return this.input_type;
      }

      return this.input_type  + this.input_case;

    },
    toSaveRegs() {
      return {
        register_1: true,
        register_2: true,
        register_destiny: true,
        }
      },
    stringTypeOptions(){
      return new StringTypes().items;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    save() {
      this.agent.register_2.value = this.formatReg2;
      
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


</style>